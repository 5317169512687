// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true
};

export const endpoint = {
  endpointParametrizationProd:"https://captaciones-int-dev.apps.ambientesbc.com/seguros/settings/api/",
  endpointProductsProd:"https://captaciones-int-dev.apps.ambientesbc.com/seguros/sales/",
  endpointPolicyProd:"https://captaciones-int-dev.apps.ambientesbc.com/seguros/soporte/api/",
  apiurl:"https://captaciones-int-dev.apps.ambientesbc.com/seguros/sales/",
  endpointLists:"https://captaciones-int-dev.apps.ambientesbc.com/seguros/sales/list/",
  urlSeguros:"https://captaciones-int-dev.apps.ambientesbc.com/seguros/",
  urlCardif:"https://webview-uat.cardif.com.co/wv_bancolombia/"
}

export const msal={
  clientId:"07f9534f-cc25-4d10-95d9-8a46a851e21b",
  redirectUri:"https://seguros-sis-mig-dev.apps.ambientesbc.com",
  tenant: '428f4e2e-13bf-4884-b364-02ef9af41a1d'
}
